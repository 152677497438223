.candidate-uploaded-card {
    border-radius: 12px;
    border: 1px solid gainsboro;
    padding: 16px 12px
}

.progress {
    width: 100%;
    border-radius: 20px;
    background-color: var(--primary);
    height: 8px;
}