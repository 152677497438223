.stats-card {
  border-radius: 12px;
  border: 1px solid gainsboro;
  padding: 24px 16px;
  width: 100%;
}

.stats-image {
  width: 56px;
  height: 56px;
  background-color: var(--primaryLightest);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}
