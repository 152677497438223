:root {
  /* Primary colors for the app */
  --primary: #7f56d9;
  --primaryDark: #161616;
  --primaryLight: #bca7ff;
  --primaryLightest: #f2eefa;

  /* Secondary colors for the app */
  --secondary: #fcff67;

  /* Basic color */
  --white: #ffffff;
  --black: #000000;
  --whitesmoke: whitesmoke;
  --gainsboro: gainsboro;

  /* Shades of gray */
  --gray: #383838;
  --grayLight: #444444;
  --textGray: #667085;
  --darkGray: #262626;
  --lightestGray: #f9fafb;

  --green: #1b923c;
  --lightGreen: #defee7;

  --blue: #417adc;
  --lightBlue: #eff8ff;
  /* Shades for displaying score */

  --red: #b42318;
  --lightRed: #fef3f2;
  --basicRed: #fa9191;

  --orange: #fb6514;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.fw-300 {
  font-weight: 300;
}

.small-avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.small-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

a {
  text-decoration: none;
}

.name-initials-avatar {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: gainsboro;
  color: var(--textGray);
  font-weight: 600;
  font-size: 16px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dotted-separator {
  border: 1px dashed var(--textGray);
  background-color: unset;
}

.no-white-space {
  white-space: nowrap;
}

.cursor-unset {
  cursor: unset !important;
}
