.table-header-area {
  background-color: var(--lightestGray);
  width: 100%;
}
.table-header-area th {
  padding: 8px 6px;
}

.table-row {
  width: 100%;
  border-bottom: 1px solid var(--whitesmoke);
  cursor: pointer;
}

.table-row:hover {
  background-color: var(--whitesmoke);
}

.table-row td {
  padding: 16px 6px;
}

.table-wrapper {
  border: 1px solid var(--gainsboro);
  border-radius: 12px;
  overflow: hidden;
}
