.jd-attributes {
  background-color: var(--primaryLightest);
  color: var(--primary);
  margin-right: 1%;
  padding: 4px 14px;
  border-radius: 20px;
  margin-bottom: 5px;
}

.interview-active {
  color: var(--green);
  background-color: var(--lightGreen);
  border-radius: 16px;
  padding: 4px 8px;
  width: fit-content;
  height: fit-content;
}

.interview-expired {
  color: var(--red);
  background-color: var(--lightRed);
  border-radius: 16px;
  padding: 4px 8px;
  width: fit-content;
  height: fit-content;
}

.interview-in-future {
  color: var(--primary);
  background-color: var(--primaryLightest);
  border-radius: 16px;
  padding: 4px 8px;
  width: fit-content;
  height: fit-content;
}

.nav-tabs > .active {
  color: var(--primary) !important;
}

.nav-tabs > .nav-link {
  color: var(--textGray);
  padding-left: 42px;
  padding-right: 42px;
}

@media only screen and (max-width: 550px) {
  .nav-tabs > .nav-link {
    padding: 10px;
  }
}

.input-container {
  position: relative;
  min-width: 250px;
}

.inputField {
  padding-left: 40px;
  width: 100%;
  box-sizing: border-box;
}

.image {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
