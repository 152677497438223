.sidebar-size {
  width: 660px;
}

.vertical-line {
  border-left: 1px solid #ccc;
  height: 80vh;
}

@media only screen and (max-width: 660px) {
  .sidebar-size {
    overflow-wrap: initial;
  }
}

.nav-pills {
  border: 1px solid #ffffff;
  overflow: hidden;
}

.pill {
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: var(--textGray);
}

.pill:hover {
  background-color: var(--whitesmoke);
}

.nav-pills > .active {
  border-right: 4px solid var(--primary);
  background-color: var(--primaryLightest);
  color: var(--primary);
}

.attr {
  max-width: 155px;
}
