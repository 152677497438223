.upload-file {
  border: 1px solid gainsboro;
  border-radius: 12px;
  text-align: center;
  padding: 30px;
  width: 100%;
  cursor: pointer;
}

.form-select {
  width: 100%;
  text-align: left;
  border: 1px solid var(--gainsboro);
  background: var(--white);
  padding: 12px;
  border-radius: 10px;
}

.select-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translate(0px, 4px);
}
